import React from "react";

const More = () => {
  return (
    <div className="call-to-action bg-dark">
      <div className="container text-center">
        <h2>...</h2>
      </div>
    </div>
  );
};

export default More;
